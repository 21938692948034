import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Renderer2,
  QueryList,
  EventEmitter,
  Output,
} from "@angular/core";
import { forkJoin, fromEvent, Subscription } from "rxjs";
import { HelperService } from "../../shared/helperService";
import { AppUsersService, DealerTransactionsService, DoctorTransactionsService, LoginsService } from "../../core/service";
import { Router } from "@angular/router";
import { AppUsersService as CustomAppUsersService } from "../../pages/custom/appUsers/appUsers.service";



@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @ViewChild('itemForm') itemForm: any;
  closeMenu: boolean = true;
  isMenuVisible: boolean = false;
  isOverlayVisible: boolean = false;
  isAdmin = false;
  sidebarClass = "";
  userInfo: any = null;
  isUserLoggedIn = false;
  hideSideBar: boolean = false;
  isOpen = false;
  approvalDoctor: any[] = [];
  approvalClient: any[] = [];
  doctorTransaction: any[] = [];
  dealerTransaction: any[] = [];
  selectedItem: any;
  passwordChangeDialog: boolean = false;
  newPassword: any;
  confirmPassword11: any;
  passwordStatus: boolean = false;
  showSuccessDialog: boolean = false;
  passwordEmpty: boolean = false;
  loading: boolean = false;
  



  private intervalId: any;
  @Output() hideSideBarOutput = new EventEmitter<boolean>(false);
  constructor(
    private renderer: Renderer2,
    public loginsService: LoginsService,
    public doctorTransactionsService: DoctorTransactionsService,
    public dealerTransactionsService: DealerTransactionsService,
    public helperService: HelperService,
    public appUsersService: AppUsersService,
    public router: Router,
    private usersService: CustomAppUsersService,

  ) {
    this.setAutoRefresh();
    this.helperService.loginChange.subscribe((loginType) => {
      this.userInfo = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
    });
    this.helperService.dataChange.subscribe((type) => {
      if (type == "approveUser") {
        this.getUserData();
        this.setAutoRefresh();
      }
    });
  }
  toggleMenu() {
    this.isOpen = !this.isOpen;
  }
  clickedElement: Subscription = new Subscription();
  ngOnInit(): void {
    this.setAutoRefresh();
    this.userInfo = this.helperService.getLoggedInUserInfo();
this.getUserData();

  }
  setAutoRefresh() {
    this.intervalId = setInterval(() => {
      // window.location.reload();
      this.getUserData();
    }, 1 * 60 * 1000); // 2 minutes in milliseconds
  }
  Open(){
    document.getElementById('ChangePassword').click();
  }
  getUserData(){


    const appUser = this.appUsersService.fetchData();
    const doctor = this.doctorTransactionsService.fetchData();
    const dealer = this.dealerTransactionsService.fetchData();
    forkJoin([
      appUser,
      doctor,
      dealer
    ]).subscribe((results) => {
      this.approvalClient = results[0].filter(
        (a) => (a.publish == "Draft" && a.role == "CLIENT")
      );
      this.approvalDoctor = results[0].filter(
        (a) => (a.publish == "Draft" && a.role == "DOCTOR")
      );
      this.doctorTransaction = results[1].filter(
        (a) => (a.isActive==null)
      );
      this.dealerTransaction = results[2].filter(
        (a) => (a.isActive==null)
      );
    });

    // this.appUsersService.getAll().subscribe((data) => {
    //   this.approvalClient = data.filter(
    //     (a) => (a.publish == "Draft" && a.role == "CLIENT")
    //   );
    //   this.approvalDoctor = data.filter(
    //     (a) => (a.publish == "Draft" && a.role == "DOCTOR")
    //   );
    // });
  }
  goto(path: string) {
    this.helperService.clearSearchHistory();
    this.helperService.routeNavigate(path);
    this.helperService.setRouteData(null);
    this.isMenuVisible = false;
    this.isOverlayVisible = false;
    console.log("akaka", path);
  }

  logout() {
    this.loginsService.clearData();
    this.helperService.logout();
    // this.helperService.routeNavigate("home");
    this.helperService.routeNavigate("admin/login");
  }
  toggleSidebar() {
    this.hideSideBar = !this.hideSideBar;
    this.hideSideBar
      ? this.hideSideBarOutput.emit(true)
      : this.hideSideBarOutput.emit(false);
  }

  passwordMatch() {
    this.loading = true;
    let condition = {};
    condition["Id"] = this.userInfo?.id;
    this.usersService.searchData(null, null, condition).subscribe((data) => {
      this.selectedItem = data;
      this.selectedItem[0].password = this.newPassword;
      this.selectedItem[0].id = this.userInfo?.id;

      if (this.newPassword?.length < 1 && this.confirmPassword11?.length < 1) {
        this.passwordEmpty = true;
        this.passwordStatus = false;
        this.loading = false;
      } else if (this.newPassword === this.confirmPassword11 && this.newPassword != undefined && this.confirmPassword11 != undefined) {
        this.passwordStatus = false;
        this.passwordEmpty = false;
        this.usersService
          .updatePassword11(this.selectedItem[0])
          .subscribe((data) => {
            this.loading = false;
            if (data == "Success") {
              this.passwordChangeDialog = false;
              this.showSuccessDialog = true;
              this.newPassword = '';
              this.confirmPassword11 = '';
              this.itemForm.reset();
            }
          });
      } else {
        this.passwordStatus = true;
        this.passwordEmpty = false;
        this.loading = false;
      }
    });
  }
}
